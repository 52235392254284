<template>
	<div>
		<nav-bar></nav-bar>
		<!-- 需求发布全流程 -->
		<div class="gaoxiao">
			<div class="banxin">
				<div class="qiyetop">
					<img src="../../../assets/img/images/tl_06.png" class="zhengpic" alt="" />
					<div class="zhengtitle">需求发布全流程</div>
					<img src="../../../assets/img/images/tr_07.png" class="zhengpic" alt="" />
				</div>
				<div class="bubox">
					<div class="buli" v-for="(item , index) in xuList" :key="index"
						:class="{buliact : isAvtive == index}" @click="xuTab(index)">
						<div class="numbox">
							{{item.num}}
						</div>
						<div class="numtxt">
							{{item.name}}
						</div>
						<img src="../../../assets/img/images/xuxian_03.png" class="xuxian" alt="" />
					</div>
				</div>
				<div class="dilan">
					<div class="dione" v-if="isAvtive === 0">
						<div class="onetop">
							<div class="onebai">
								<div class="onetitle">
									企业名称
								</div>
								<div class="onedis">
									<img src="../../../assets/img/images/onea_03.png" class="onepic" alt="" />
									<div class="onetxt">
										未填写
									</div>
								</div>
								<div class="onedis">
									<img src="../../../assets/img/images/oneb_03.png" class="onepic" alt="" />
									<div class="onetxt">
										未填写
									</div>
								</div>
							</div>
							<div class="onebai">
								<div class="onetime">
									2021-04-17 15: 47:52
								</div>
								<div class="oneti">
									需求发布标题标题标题标题标题
								</div>
								<div class="oneti">
									审核状态：
									<span>通过审核</span>
								</div>
							</div>
						</div>
						<div class="tcenter">
							<img src="../../../assets/img/images/xubai_03.png" class="xupic" alt="" />
							<div class="zhelitxt">
								1.这里展示企业简介及发布的需求
							</div>
							<div class="nextone" @click="nextTab">
								下一步
							</div>
						</div>
					</div>
					<div class="dione" v-if="isAvtive === 1">
						<div class="onetop">
							<div class="onebai">
								<div class="onetitle">
									智能匹配
								</div>
								<div class="onedis">
									<div class="onetxt">
										2021-04-17 15:47:55
									</div>
								</div>
								<div class="onedis">
									<div class="onetxt">
										系统第二次生成匹配报告
									</div>
								</div>
							</div>
							<div class="onebai">
								<div class="onedis">
									<div class="onetxt">
										&nbsp;
									</div>
								</div>
								<div class="oneti">
									专家：
									<span>1人</span>
								</div>
								<div class="oneti">
									成果：
									<span>0项</span>
								</div>
								<div class="oneti">
									科研团队：
									<span>0人</span>
								</div>
							</div>
						</div>
						<div class="tcenter">
							<img src="../../../assets/img/images/xubai_03.png" class="xupic" alt="" />
							<div class="zhelitxt">
								2.发布需求后，智能匹配工具立即生成一份需求匹配报告
							</div>
							<div class="nextone" @click="nextTab">
								下一步
							</div>
						</div>
					</div>

					<div class="dione" v-if="isAvtive === 2">
						<div class="onetop">
							<div class="onebai">
								<div class="onetitle">
									需求评估
								</div>
								<div class="onedis">
									<div class="onetxt">
										需求伯乐创新创业的路上
									</div>
								</div>
								<div class="onedis">
									<div class="onetxt">
										评估状态：以评估
									</div>
								</div>
							</div>
							<div class="onebai">
								<div class="onetime">
									2021-04-17 15: 47:52
								</div>
								<div class="xingbox">
									<div class="xingtxt">
										规范程度
									</div>
									<img src="../../../assets/img/images/caa.png" class="pic" alt="">
									<img src="../../../assets/img/images/caa.png" class="pic" alt="">
									<img src="../../../assets/img/images/caa.png" class="pic" alt="">
									<img src="../../../assets/img/images/ca.png" class="pic" alt="">
									<img src="../../../assets/img/images/ca.png" class="pic" alt="">
									<div class="huitxt">一般</div>
								</div>
								<div class="xingbox">
									<div class="xingtxt">
										解决难度
									</div>
									<img src="../../../assets/img/images/caa.png" class="pic" alt="">
									<img src="../../../assets/img/images/caa.png" class="pic" alt="">
									<img src="../../../assets/img/images/caa.png" class="pic" alt="">
									<img src="../../../assets/img/images/ca.png" class="pic" alt="">
									<img src="../../../assets/img/images/ca.png" class="pic" alt="">
									<div class="huitxt">一般</div>
								</div>
								<div class="xingbox">
									<div class="xingtxt">
										预计周期 30天
									</div>
								</div>
							</div>
						</div>
						<div class="tcenter">
							<img src="../../../assets/img/images/xubai_03.png" class="xupic" alt="" />
							<div class="zhelitxt">
								3.需求通过评审后，工作人员评估需求的规范程度、解决难度和预计周期
							</div>
							<div class="nextone" @click="nextTab">
								下一步
							</div>
						</div>
					</div>

					<div class="dione" v-if="isAvtive === 3">
						<div class="onetop">
							<div class="onebai">
								<div class="onetitle">
									合同签订
								</div>
								<div class="onedis">
									<div class="onetxt">
										2021-04-17 15:47:55
									</div>
								</div>
								<div class="onedis">
									<div class="onetxt">
										系统第二次生成匹配报告
									</div>
								</div>
							</div>
						</div>
						<div class="tcenter">
							<img src="../../../assets/img/images/xubai_03.png" class="xupic" alt="" />
							<div class="zhelitxt">
								4.线下签订合同后，工作人员上传合同
							</div>
							<div class="nextone" @click="nextTab">
								下一步
							</div>
						</div>
					</div>

					<div class="dione" v-if="isAvtive === 4">
						<div class="onetop">
							<div class="onebai">
								<div class="onetitle">
									推荐专家
								</div>
								<div class="oneti">
									2021-04-17 15: 47:52</br>
									第二次生成推荐方案
								</div>
								<div class="oneti">
									专家：
									<span>2人</span>
								</div>
							</div>
							<div class="onebai">
								<div class="onetime">
									2021-04-17 15: 47:52
								</div>

								<div class="oneti">
									第二次生成推荐方案
								</div>
								<div class="oneti">
									专家：
									<span>2人</span>
								</div>
							</div>
						</div>
						<div class="tcenter">
							<img src="../../../assets/img/images/xubai_03.png" class="xupic" alt="" />
							<div class="zhelitxt">

								5.工作人员为企业生成推荐方案
							</div>
							<div class="nextone" @click="nextTab">
								下一步
							</div>
						</div>
					</div>

					<div class="dione" v-if="isAvtive === 5">
						<div class="onetop">
							<div class="onebai">
								<div class="onetitle">
									筛选专家
								</div>

								<div class="oneti">
									姓名：王某某</br>
									工作单位：xxx有限公司</br>
									所在地：文案文案文案</br>
										状态：
										<span>已审核</span>
								</div>
							</div>
							<div class="onebai">
								<div class="onetime">
									2021-04-17 15: 47:52
								</div>
								<div class="oneti">
									姓名：王某某</br>
									工作单位：xxx有限公司</br>
									所在地：文案文案文案</br>
								</div>
							</div>
						</div>
						<div class="tcenter">
							<img src="../../../assets/img/images/xubai_03.png" class="xupic" alt="" />
							<div class="zhelitxt">

								6.企业亲自或委托工作人员进行方案筛选
							</div>
							<div class="nextone" @click="nextTab">
								下一步
							</div>
						</div>
					</div>
					<div class="dione" v-if="isAvtive === 6">
						<div class="onetop">
							<div class="onebai">
								<div class="onetitle">
									服务跟踪
								</div>
								<div class="onedis">
									<div class="onetxt">
										2021-04-17 15:47:55
									</div>
								</div>
								<div class="onedis">
									<div class="onetxt">
										关于专家某某某的第一次跟踪记录
									</div>
								</div>
							</div>
						</div>
						<div class="tcenter">
							<img src="../../../assets/img/images/xubai_03.png" class="xupic" alt="" />
							<div class="zhelitxt">
								7.记录每一次的服务跟踪情况
							</div>
							<div class="nextone" @click="nextTab">
								下一步
							</div>
						</div>
					</div>
					<div class="dione" v-if="isAvtive === 7">
						<div class="onetop">
							<div class="onebai">
								<div class="onetitle">
									服务结果
								</div>
								<div class="onedis">
									<div class="onetxt">
										2021-04-17 15:47:55
									</div>
								</div>
								<div class="onedis">
									<div class="onetxt">
										对接成功
									</div>
								</div>
								<div class="onedis">
									<div class="onetxt">
										对接专家：某某某
									</div>
								</div>
							</div>
						</div>
						<div class="tcenter">
							<img src="../../../assets/img/images/xubai_03.png" class="xupic" alt="" />
							<div class="zhelitxt">
								8.登记本次服务结果
							</div>
							<div class="nextone" >
								完成
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<tab-bar></tab-bar>
	</div>

</template>

<script>
	import $ from "jquery";
	import FromList from "../../../common/input";
	import NavBar from '../../../components/common/NavBar';
	import TabBar from '../../../components/common/TabBar';
	import {
		Wisdom,
		achievementsLists,
		Data
	} from "../../../common/js/api";
	import {
		zhi
	} from "../../common/js/api";
	import {
		formatDate
	} from "../../../common/js/times"; //时间

	export default {
		name: "zhichanyunForm",
		components: {
			NavBar,
			TabBar,
			FromList,
			NumberRoller: () => import("@/common/number-roller.vue"),
		},
		data() {
			return {
				xuList: [{
						id: 1,
						num: '1',
						name: '需求发布'
					},
					{
						id: 2,
						num: '2',
						name: '智能匹配'
					},
					{
						id: 3,
						num: '3',
						name: '需求评估'
					},
					{
						id: 4,
						num: '4',
						name: '合同签订'
					},
					{
						id: 5,
						num: '5',
						name: '推荐专家'
					},
					{
						id: 6,
						num: '6',
						name: '筛选专家'
					},
					{
						id: 7,
						num: '7',
						name: '服务跟踪'
					},
					{
						id: 8,
						num: '8',
						name: '服务结果'
					}
				],
				isAvtive: 0,
			};
		},
		created() {

		},
		mounted() {

		},
		methods: {
			xuTab(index) {
				this.isAvtive = index
			},
			nextTab() {
				this.isAvtive++
			}
		}
	};
</script>

<style scoped lang="less">
	html {
		background-color: #f6f6f6 !important;
	}

	.banxin {
		width: 1400px;
		margin: 0 auto;
	}

	.pt60 {
		padding-top: 60px;
	}

	.qiyetop {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 60px;

		.zhengpic {
			width: 118px;
			height: 27px;
			flex-shrink: 0;
		}

		.zhengtitle {
			font-size: 36px;
			color: #222222;
			font-weight: bold;
			padding: 0 35px;
		}

		.zhengbai {
			font-size: 36px;
			color: #ffffff;
			font-weight: bold;
			padding: 0 35px;
		}
	}

	.gongtop {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 25px;
		margin-top: 136px;

		.zhengpic {
			width: 118px;
			height: 27px;
			flex-shrink: 0;
		}

		.zhengtitle {
			font-size: 36px;
			color: #222222;
			font-weight: bold;
			padding: 0 35px;
		}

		.zhengbai {
			font-size: 36px;
			color: #ffffff;
			font-weight: bold;
			padding: 0 35px;
		}
	}

	.gaoxiao {
		padding: 60px 0 80px;
		background-color: #f6f6f6;

		.bubox {
			background-color: #ffffff;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 123px;

			.buli:last-child {
				.xuxian {
					display: none;
				}
			}

			.buli {
				width: 170px;
				position: relative;
				text-align: center;
				cursor: pointer;

				.numbox {
					width: 30px;
					height: 30px;
					border: 1px solid #d3d3d3;
					border-radius: 50%;
					margin: 0 auto;
					font-size: 16px;
					color: #999999;
					line-height: 30px;
				}

				.numtxt {
					padding-top: 12px;
					color: #2c2c2c;
					font-weight: bold;
					font-size: 18px;
				}

				.xuxian {
					width: 69px;
					height: 1px;
					position: absolute;
					right: -30px;
					top: 15px;
				}
			}

			.buliact {
				.numbox {
					background-color: #0289f4;
					border: 1px solid #0289f4;
					color: #ffffff;
				}
			}
		}

		.dilan {
			background-color: #0289f4;
			height: 520px;

			.dione {
				.onetop {
					display: flex;
					align-items: center;
					justify-content: center;
					padding-top: 60px;

					.onebai {
						width: 500px;
						height: 185px;
						margin: 0 15px;
						background-color: #ffffff;
						border-radius: 7px;
						padding: 0 30px;

						.onetitle {
							font-size: 24px;
							color: #222222;
							font-weight: bold;
							padding: 20px 0;
						}

						.onedis {
							display: flex;
							align-items: center;
							padding-bottom: 20px;

							.onepic {
								width: 20px;
								height: 20px;
							}

							.onetxt {
								font-size: 18px;
								color: #222222;
								padding-left: 10px;
							}
						}

						.onetime {
							font-size: 16px;
							color: #888888;
							padding: 21px 0 10px;
						}

						.oneti {
							font-size: 18px;
							color: #222222;
							padding-bottom: 10px;
							line-height: 30px;
							span {
								color: #0289f4;
								font-size: 18px;
							}
						}

						.xingbox {
							display: flex;
							align-items: center;
							padding-bottom: 10px;

							.xingtxt {
								font-size: 18px;
								color: #0289f4;
								padding-right: 10px;
							}

							.pic {
								width: 18px;
								height: 18px;
								margin-right: 6px;
							}

							.huitxt {
								font-size: 18px;
								color: #999999;
							}
						}
					}
				}

				.tcenter {
					text-align: center;
				}

				.xupic {
					width: 310px;
					height: 123px;
					margin: 0 auto;
				}

				.zhelitxt {
					color: #fafcff;
					font-size: 18px;
					padding: 10px 0 26px;
				}

				.nextone {
					width: 165px;
					height: 48px;
					border: 1px solid #ffffff;
					cursor: pointer;
					color: #ffffff;
					font-size: 18px;
					line-height: 48px;
					margin: 0 auto;
				}
			}
		}
	}

	@media screen and (max-width: 1200px) {
		.banxin {
			width: 98%;
			margin: 0 auto;
			display: block;
			overflow: hidden;
		}

		.gaoxiao {
			margin-top: 15rem;
		}

		.qiyetop .zhengtitle {
			font-size: 3rem;
		}

		.gaoxiao .bubox {
			flex-wrap: wrap;
			height: auto;
			padding-bottom: 2rem;
		}

		.gaoxiao .bubox .buli {
			width: 25%;
			padding: 2rem 0 0 0;
		}

		.gaoxiao .bubox .buli .numbox {
			width: 2.2rem;
			height: 2.2rem;
			font-size: 1.6rem;
			line-height: 2.2rem;
			flex-shrink: 0;
		}

		.gaoxiao .bubox .buli .numtxt {
			font-size: 1.6rem;
			padding-top: 1rem;
		}
		.gaoxiao .dilan .dione .onetop .onebai{
			height: auto;
		}
		.gaoxiao .dilan .dione .onetop .onebai .onetitle {
			font-size: 1.7rem;
			padding: 1rem 0;
		}

		.gaoxiao .dilan .dione .onetop .onebai .onedis .onetxt {
			font-size: 1.4rem;
			line-height: 1.3rem;
		}

		.gaoxiao .dilan .dione .onetop .onebai .onetime {
			font-size: 1.4rem;
			padding: 1.2rem 0;
		}

		.gaoxiao .dilan .dione .onetop .onebai .oneti {
			font-size: 1.3rem;
			
		}

		.gaoxiao .dilan .dione .zhelitxt {
			font-size: 1.6rem;
		}

		.gaoxiao .dilan .dione .nextone {
			font-size: 1.6rem;
		}
	}
</style>